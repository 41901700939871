<template>
  <div class="about">
    <div class="about-item">
      <el-container>
        <!-- 导航盒子 -->
        <el-aside width="170px">
          <el-menu router :default-active="defaultActive" class="el-menu-vertical-demo"
                   text-color="#000" active-text-color="#2B72E0">
            <!-- <el-menu-item v-if="true" index="/aboutUs/aboutCompany">
              <span slot="title">公司简介</span>

            </el-menu-item> -->
            <el-menu-item index="/aboutUs/aboutProduct" class="product-item">
              <span slot="title">产品介绍</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <!-- 路由填充位 -->
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      defaultActive: ''
    }
  },
  created () {
    this.defaultActive = this.$route.path
  }
}
</script>
<style lang="less" scoped>
.about-item {
  width: 100%;
  // height: 100%;
  background-color: #f5f7fb;
  .iconfont {
    font-size: 22px;
    margin-right: 10px;
  }
  .product {
    font-size: 30px;
  }
  // .product-item {
  //   position: relative;
  //   left: -5px;
  // }
}
.el-container {
  width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
  .el-aside {
    margin-right: 20px;
  }
  .el-menu {
    background-color: #fff;
    border: 0;
    border-radius: 2px;
    .el-menu-item {
      font-size: 20px;
      text-align: center;
    }
    .el-menu-item:hover {
      background-color: transparent !important;
      color: #2b72e0 !important;
    }
    .is-active {
      position: relative;
      background-color: transparent !important;
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 30px;
        margin: auto 0;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        border: 1px solid #2b72e0;
      }
    }
  }
  .el-main {
    padding: 50px 35px 35px;
    background-color: #fff;
    border-radius: 2px;
  }
}
</style>
